<template>
  <section>
    <a-row class="search-container" type="flex" justify="space-between">
      <a-form
        :form="serviveForm"
        layout="inline"
        @submit="submitForm"
        class="m-t-14"
      >
        <a-form-item label="材料名称">
          <a-input
            autocomplete="off"
            v-decorator="['name']"
            placeholder="请输入"
          />
        </a-form-item>
        <!-- <a-form-item label="所属专业">
          <a-select placeholder="请选择"></a-select>
        </a-form-item> -->
        <a-form-item label="所属分类">
          <a-cascader
            :options="options"
            @change="value => onChangeMaterial(value)"
            placeholder="请选择"
            :display-render="displayRender"
            v-decorator="['categoryId']"
          />
        </a-form-item>

        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit">查询</a-button>
          <a-button class="m-r-8" @click="reset">重置</a-button>
        </a-form-item>
      </a-form>
      <router-link to="/mms/addMajor">
        <a-button type="primary" class="m-t-18">添加材料</a-button>
      </router-link>
    </a-row>

    <a-row type="flex" :gutter="24" class="list-card-container">
      <a-col :span="6" v-for="(item, key) in listMap" :key="key" class="m-t-16">
        <a-row class="list-card-bg">
          <a-row class="list-wrap">
            <a-col>
              <a-row class="list-item" style="padding-bottom:8px">
                {{ item.name }}
              </a-row>
              <a-row class="list-item" style="padding-bottom:8px">
                所属专业:{{ item.categoryParentName }}
              </a-row>
              <a-row class="list-item">
                所属分类:{{ item.categoryName }}
              </a-row>
              <a-row class="list-btn-group-position m-t-14">
                <a-button @click="editItem(item)" size="small" style="background:#2f5994"
                  >编辑</a-button
                >
                <a-popconfirm
                  @confirm="deleteItem(item)"
                  title="确定删除该类型?"
                  ><a-button size="small" style="background:#2f5994"
                    >删除</a-button
                  ></a-popconfirm
                >
              </a-row>
            </a-col>
          </a-row>
        </a-row>
      </a-col>
    </a-row>

    <section class="pagination-container  paginatioPositon">
      <a-pagination
        size="default"
        v-model="current"
        :total="total"
        :page-size="pageSize"
        @change="onChange"
      />
    </section>
  </section>
</template>
<script>
export default {
  data() {
    return {
      serviveForm: this.$form.createForm(this),
      options: [],
      categoryId: "",
      name: "",
      current: 1,
      total: 0,
      pageSize: 16,

      listMap: [
       
      ]
    };
  },
  created() {
    this.getCategoryList();
    this.getMaterialList();
  },
  methods: {
    // 材料下拉
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    // 分类列表
    getCategoryList() {
      this.$api.getCategoryList().then(res => {
        if (res.code == "200") {
          let options = res.data;
          let arr = this.formTreeData(options);
          this.options = arr;
        }
      });
    },
    // 材料列表
    getMaterialList() {
      let params = {
        categoryId: this.categoryId,
        name: this.name,
        current: this.current
      };
      this.$api.getMaterialList(params).then(res => {
        if (res.code == "200") {
          console.log(res);
          this.listMap = res.data.records;
          this.total = res.data.total;
        }
      });
    },
    // 选择材料
    onChangeMaterial(value) {
      this.categoryId = value[value.length - 1];
      console.log(this.categoryId);
    },
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.serviveForm.getFieldsValue();
      this.name = formVal.name;
      this.current = 1;
      this.getMaterialList();
    },
    // 重置
    reset() {
      this.categoryId = "";
      this.name = "";
      this.current = 1;
      // 重置
      this.serviveForm.resetFields();
      this.getMaterialList();
    },
    // 分页
    onChange(current) {
      console.log(current);
      // this.pageSize = pageSize;
      this.current = current;
      this.getMaterialList();
    },
    // 编辑
    editItem(item){

       this.$router.push({ path: "/mms/editMajor?id="+item.id });
    },
    // 删除
    deleteItem(item) {
      this.$api.deleteMaterialinfo(item.id).then(res => {
        if (res.code == "200") {
          this.getMaterialList();
        }
      });
    },
    // 树状图
    formTreeData(arr) {
      arr.forEach(item => {
        item.value = item.id;
        item.label = item.name;
        // item.scopedSlots = { title: "value" };
        if (item.children && item.children.length > 0) {
          this.formTreeData(item.children);
        }
      });
      return arr;
    }
  }
};
</script>
<style lang="scss" scoped>
.list-wrap {
  padding: 16px 10px 24px 10px;
}
.list-btn-group-position {
  bottom: -12px !important;
}
.list-btn-group-position button {
  background: #3f72ba !important;
}
/deep/.ant-cascader-picker-label {
  color: #93bbf3 !important;
}
/deep/.ant-cascader-picker {
  background-color: #1c3d6c;
  border: none;
  outline: none;
}
/deep/.ant-cascader-picker-arrow {
  color: #93bbf3 !important;
}
</style>